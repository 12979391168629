import { Link, useLocation } from '@remix-run/react';
import clsx from 'clsx';
import type { ReactNode } from 'react';

export function NavItem({
  href,
  children,
}: {
  href: string;
  children: ReactNode;
}) {
  let location = useLocation();
  let isActive = location.pathname === href;

  return (
    <li>
      <Link
        to={href}
        className={clsx(
          'relative block px-3 py-2 transition',
          isActive
            ? 'text-secondary dark:text-sky-400'
            : 'hover:text-secondary dark:hover:text-sky-400',
        )}
      >
        {children}
        {isActive && (
          <span className="absolute inset-x-1 -bottom-px h-px bg-gradient-to-r from-sky-500/0 via-sky-500/40 to-teal-500/0 dark:from-sky-400/0 dark:via-sky-400/40 dark:to-sky-400/0" />
        )}
      </Link>
    </li>
  );
}
