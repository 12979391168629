import type { ComponentPropsWithoutRef } from 'react';

// function ArrowLeftIcon(props: React.ComponentPropsWithoutRef<'svg'>) {
//   return (
//     <svg viewBox="0 0 16 16" fill="none" aria-hidden="true" {...props}>
//       <path
//         d="M7.25 11.25 3.75 8m0 0 3.5-3.25M3.75 8h8.5"
//         strokeWidth="1.5"
//         strokeLinecap="round"
//         strokeLinejoin="round"
//       />
//     </svg>
//   );
// }

export function UnknownIcon(props: ComponentPropsWithoutRef<'svg'>) {
  return (
    <svg viewBox="0 0 256 256" fill="none" aria-hidden="true" {...props}>
      <rect
        x="17"
        y="45"
        width="130.32"
        height="135.344"
        rx="18"
        fill="black"
        stroke="white"
        stroke-width="4"
      />
      {/* U Letter */}
      <path
        d="M89.2105 129.913C89.2105 130.351 89.1324 130.757 88.9761 131.132C88.8199 131.507 88.6011 131.835 88.3199 132.116C88.0386 132.382 87.7105 132.593 87.3355 132.749C86.9605 132.906 86.5621 132.984 86.1402 132.984H72.4058C71.109 132.984 69.7965 132.843 68.4683 132.562C67.1402 132.281 65.8511 131.851 64.6011 131.273C63.3668 130.679 62.2027 129.929 61.109 129.023C60.0152 128.116 59.0543 127.046 58.2261 125.812C57.4136 124.562 56.773 123.14 56.3043 121.546C55.8355 119.937 55.6011 118.148 55.6011 116.179V99.3743H61.6949V116.179C61.6949 117.898 61.9293 119.351 62.398 120.538C62.8824 121.726 63.4918 122.718 64.2261 123.515C64.9605 124.312 65.7652 124.937 66.6402 125.39C67.5152 125.843 68.3433 126.179 69.1246 126.398C69.9215 126.616 70.6168 126.757 71.2105 126.82C71.8199 126.866 72.2183 126.89 72.4058 126.89H83.1168V99.3743H89.2105V129.913Z"
        fill="white"
      />
      <rect
        x="109.68"
        y="75.6558"
        width="130.32"
        height="135.344"
        rx="18"
        fill="black"
        stroke="white"
        stroke-width="4"
      />
      {/* K Letter */}
      <path
        d="M177.964 163.639H169.316L155.605 149.905H151.316V143.764H155.605L169.316 130.03H177.964L160.691 146.835L177.964 163.639ZM148.878 163.639H142.784V130.03H148.878V163.639Z"
        fill="white"
      />
    </svg>
  );
}

export function CloseIcon(props: ComponentPropsWithoutRef<'svg'>) {
  return (
    <svg viewBox="0 0 24 24" aria-hidden="true" {...props}>
      <path
        d="m17.25 6.75-10.5 10.5M6.75 6.75l10.5 10.5"
        fill="none"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function ChevronDownIcon(props: ComponentPropsWithoutRef<'svg'>) {
  return (
    <svg viewBox="0 0 8 6" aria-hidden="true" {...props}>
      <path
        d="M1.75 1.75 4 4.25l2.25-2.5"
        fill="none"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function MailIcon(props: ComponentPropsWithoutRef<'svg'>) {
  return (
    <svg
      viewBox="0 0 24 24"
      fill="none"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
      aria-hidden="true"
      {...props}
    >
      <path
        d="M2.75 7.75a3 3 0 0 1 3-3h12.5a3 3 0 0 1 3 3v8.5a3 3 0 0 1-3 3H5.75a3 3 0 0 1-3-3v-8.5Z"
        className="fill-zinc-100 stroke-zinc-400 dark:fill-zinc-100/10 dark:stroke-zinc-500"
      />
      <path
        d="m4 6 6.024 5.479a2.915 2.915 0 0 0 3.952 0L20 6"
        className="stroke-zinc-400 dark:stroke-zinc-500"
      />
    </svg>
  );
}

export function MailIcon2(props: React.ComponentPropsWithoutRef<'svg'>) {
  return (
    <svg viewBox="0 0 24 24" aria-hidden="true" {...props}>
      <path
        fillRule="evenodd"
        d="M6 5a3 3 0 0 0-3 3v8a3 3 0 0 0 3 3h12a3 3 0 0 0 3-3V8a3 3 0 0 0-3-3H6Zm.245 2.187a.75.75 0 0 0-.99 1.126l6.25 5.5a.75.75 0 0 0 .99 0l6.25-5.5a.75.75 0 0 0-.99-1.126L12 12.251 6.245 7.187Z"
      />
    </svg>
  );
}

export function BriefcaseIcon(props: ComponentPropsWithoutRef<'svg'>) {
  return (
    <svg
      viewBox="0 0 24 24"
      fill="none"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
      aria-hidden="true"
      {...props}
    >
      <path
        d="M2.75 9.75a3 3 0 0 1 3-3h12.5a3 3 0 0 1 3 3v8.5a3 3 0 0 1-3 3H5.75a3 3 0 0 1-3-3v-8.5Z"
        className="fill-zinc-100 stroke-zinc-400 dark:fill-zinc-100/10 dark:stroke-zinc-500"
      />
      <path
        d="M3 14.25h6.249c.484 0 .952-.002 1.316.319l.777.682a.996.996 0 0 0 1.316 0l.777-.682c.364-.32.832-.319 1.316-.319H21M8.75 6.5V4.75a2 2 0 0 1 2-2h2.5a2 2 0 0 1 2 2V6.5"
        className="stroke-zinc-400 dark:stroke-zinc-500"
      />
    </svg>
  );
}

export function ArrowDownIcon(props: ComponentPropsWithoutRef<'svg'>) {
  return (
    <svg viewBox="0 0 16 16" fill="none" aria-hidden="true" {...props}>
      <path
        d="M4.75 8.75 8 12.25m0 0 3.25-3.5M8 12.25v-8.5"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
