import type {
  LinksFunction,
  // LoaderFunctionArgs,
  MetaFunction,
} from '@remix-run/cloudflare';
import * as React from 'react';
import {
  Links,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  isRouteErrorResponse,
  // json,
  // useLoaderData,
  useRouteError,
} from '@remix-run/react';
import stylesUrl from '~/styles/tailwind.css?url';
// import { type Menu, ErrorLayout, Layout } from './layout';
import { Layout } from '~/components/Layout';
import { ErrorLayout } from '~/components/ErrorLayout';
import { GoogleTagManager } from '~/components/GoogleTagManager';

const GTM_ID = 'GTM-KZKVBQ4R';

export const links: LinksFunction = () => {
  return [{ rel: 'stylesheet', href: stylesUrl }];
};

export const meta: MetaFunction = () => {
  return [
    { charset: 'utf-8' },
    { name: 'viewport', content: 'width=device-width, initial-scale=1' },
    { title: 'unknownkind' },
  ];
};

// export function loader({ context }: LoaderFunctionArgs) {
// 	const menus: Menu[] = [
// 		{
// 			title: 'Docs',
// 			links: [
// 				{
// 					title: 'Overview',
// 					to: '/',
// 				},
// 			],
// 		},
// 		{
// 			title: 'Useful links',
// 			links: [
// 				{
// 					title: 'GitHub',
// 					to: `https://github.com/${context.env.GITHUB_OWNER}/${context.env.GITHUB_REPO}`,
// 				},
// 				{
// 					title: 'Remix docs',
// 					to: 'https://remix.run/docs',
// 				},
// 				{
// 					title: 'Cloudflare docs',
// 					to: 'https://developers.cloudflare.com/pages',
// 				},
// 			],
// 		},
// 	];

// 	return json({
// 		menus,
// 	});
// }

function Favicon() {
  return (
    <>
      <link
        rel="apple-touch-icon"
        sizes="180x180"
        href="/apple-touch-icon.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="32x32"
        href="/favicon-32x32.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="16x16"
        href="/favicon-16x16.png"
      />
      <link rel="manifest" href="/site.webmanifest" />
    </>
  );
}

export default function App() {
  // const { menus } = useLoaderData<typeof loader>();

  return (
    <Document>
      <div className="flex w-full">
        <Layout>
          <Outlet />
        </Layout>
      </div>
    </Document>
  );
}

function Document({
  children,
  title,
}: {
  children: React.ReactNode;
  title?: string;
}) {
  return (
    <html lang="en" className="h-full antialiased">
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        {title ? <title>{title}</title> : null}
        <Favicon />
        <Meta />
        <Links />
        <GoogleTagManager gtmId={GTM_ID} />
      </head>
      <body className="flex h-full bg-zinc-50 dark:bg-zinc-800">
        <noscript>
          <iframe
            title="Google Tag Manager"
            src={`https://www.googletagmanager.com/ns.html?id=${GTM_ID}`}
            height="0"
            width="0"
            style={{ display: 'none', visibility: 'hidden' }}
          />
        </noscript>
        {children}
        <ScrollRestoration />
        <Scripts />
      </body>
    </html>
  );
}

export function ErrorBoundary() {
  const error = useRouteError();

  // Log the error to the console
  console.error(error);

  if (isRouteErrorResponse(error)) {
    const title = `${error.status} ${error.statusText}`;

    let message;
    switch (error.status) {
      case 401:
        message =
          'Oops! Looks like you tried to visit a page that you do not have access to.';
        break;
      case 404:
        message =
          'Oops! Looks like you tried to visit a page that does not exist.';
        break;
      default:
        message = JSON.stringify(error.data, null, 2);
        break;
    }

    return (
      <Document title={title}>
        <ErrorLayout title={title} description={message} />
      </Document>
    );
  }

  return (
    <Document title="Error!">
      <ErrorLayout
        title="There was an error"
        description={`${error}`}
      ></ErrorLayout>
    </Document>
  );
}
