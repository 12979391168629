export function ErrorLayout({
  title,
  description,
}: {
  title?: string;
  description?: string;
}) {
  return (
    <div className="flex h-screen items-center">
      <div className="mx-auto p-5">
        <h2 className="text-xl">{title}</h2>
        <p className="py-2">{description}</p>
      </div>
    </div>
  );
}
