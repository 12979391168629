import { useEffect, useRef } from 'react';
// import Image from 'next/image';
import { Link, useLocation } from '@remix-run/react';
// import { useTheme } from 'next-themes';
import clsx from 'clsx';

import { Container } from '~/components/Container';
import logo_flat from '~/assets/unknown_flat.svg';
import Nav from './Nav';
import { links } from '~/data/nav';

// function SunIcon(props: React.ComponentPropsWithoutRef<'svg'>) {
// 	return (
// 		<svg
// 			viewBox="0 0 24 24"
// 			strokeWidth="1.5"
// 			strokeLinecap="round"
// 			strokeLinejoin="round"
// 			aria-hidden="true"
// 			{...props}
// 		>
// 			<path d="M8 12.25A4.25 4.25 0 0 1 12.25 8v0a4.25 4.25 0 0 1 4.25 4.25v0a4.25 4.25 0 0 1-4.25 4.25v0A4.25 4.25 0 0 1 8 12.25v0Z" />
// 			<path
// 				d="M12.25 3v1.5M21.5 12.25H20M18.791 18.791l-1.06-1.06M18.791 5.709l-1.06 1.06M12.25 20v1.5M4.5 12.25H3M6.77 6.77 5.709 5.709M6.77 17.73l-1.061 1.061"
// 				fill="none"
// 			/>
// 		</svg>
// 	);
// }

// function MoonIcon(props: React.ComponentPropsWithoutRef<'svg'>) {
// 	return (
// 		<svg viewBox="0 0 24 24" aria-hidden="true" {...props}>
// 			<path
// 				d="M17.25 16.22a6.937 6.937 0 0 1-9.47-9.47 7.451 7.451 0 1 0 9.47 9.47ZM12.75 7C17 7 17 2.75 17 2.75S17 7 21.25 7C17 7 17 11.25 17 11.25S17 7 12.75 7Z"
// 				strokeWidth="1.5"
// 				strokeLinecap="round"
// 				strokeLinejoin="round"
// 			/>
// 		</svg>
// 	);
// }

// function ThemeToggle() {
// 	let { resolvedTheme, setTheme } = useTheme();
// 	let otherTheme = resolvedTheme === 'dark' ? 'light' : 'dark';
// 	let [mounted, setMounted] = useState(false);

// 	useEffect(() => {
// 		setMounted(true);
// 	}, []);

// 	return (
// 		<button
// 			type="button"
// 			aria-label={mounted ? `Switch to ${otherTheme} theme` : 'Toggle theme'}
// 			className="group rounded-full bg-white/90 px-3 py-2 shadow-lg shadow-zinc-800/5 ring-1 ring-zinc-900/5 backdrop-blur transition dark:bg-zinc-800/90 dark:ring-white/10 dark:hover:ring-white/20"
// 			onClick={() => setTheme(otherTheme)}
// 		>
// 			<SunIcon className="h-6 w-6 fill-zinc-100 stroke-zinc-500 transition group-hover:fill-zinc-200 group-hover:stroke-zinc-700 dark:hidden [@media(prefers-color-scheme:dark)]:fill-teal-50 [@media(prefers-color-scheme:dark)]:stroke-teal-500 [@media(prefers-color-scheme:dark)]:group-hover:fill-teal-50 [@media(prefers-color-scheme:dark)]:group-hover:stroke-teal-600" />
// 			<MoonIcon className="hidden h-6 w-6 fill-zinc-700 stroke-zinc-500 transition dark:block [@media(prefers-color-scheme:dark)]:group-hover:stroke-zinc-400 [@media_not_(prefers-color-scheme:dark)]:fill-teal-400/10 [@media_not_(prefers-color-scheme:dark)]:stroke-teal-500" />
// 		</button>
// 	);
// }

function clamp(number: number, a: number, b: number) {
  let min = Math.min(a, b);
  let max = Math.max(a, b);
  return Math.min(Math.max(number, min), max);
}

function LogoContainer({
  className,
  ...props
}: React.ComponentPropsWithoutRef<'div'>) {
  return (
    <div
      className={clsx(
        className,
        // 'h-10 w-10 rounded-xl bg-white/90 p-0.5 shadow-lg shadow-zinc-800/5 ring-1 ring-zinc-900/5 backdrop-blur dark:bg-zinc-800/90 dark:ring-white/10',
        'h-10 w-16 bg-white/90 backdrop-blur dark:bg-zinc-800/90',
      )}
      {...props}
    />
  );
}

function Logo({
  large = false,
  className,
  to,
  src,
  ...props
}: Omit<React.ComponentPropsWithoutRef<typeof Link>, 'href'> & {
  large?: boolean;
  src?: string;
}) {
  return (
    <Link
      to={to ?? '/'}
      aria-label="Home"
      className={clsx(className, 'pointer-events-auto')}
      {...props}
    >
      <img
        src={src}
        alt="logo"
        // sizes={large ? '4rem' : '2.25rem'}
        className={clsx(
          // 'rounded-full bg-zinc-100 object-cover dark:bg-zinc-800',
          'bg-white/90 object-cover backdrop-blur dark:bg-zinc-800',
          large ? 'h-16 w-28' : 'h-10 w-16',
        )}
        // priority
      />
    </Link>
  );
}

export function Header() {
  let location = useLocation();
  let isHomePage = location.pathname === '/';

  let headerRef = useRef<React.ElementRef<'div'>>(null);
  let logoRef = useRef<React.ElementRef<'div'>>(null);
  let isInitial = useRef(true);

  useEffect(() => {
    let downDelay = logoRef.current?.offsetTop ?? 0;
    let upDelay = 64;

    function setProperty(property: string, value: string) {
      document.documentElement.style.setProperty(property, value);
    }

    function removeProperty(property: string) {
      document.documentElement.style.removeProperty(property);
    }

    function updateHeaderStyles() {
      if (!headerRef.current) {
        return;
      }

      let { top, height } = headerRef.current.getBoundingClientRect();
      let scrollY = clamp(
        window.scrollY,
        0,
        document.body.scrollHeight - window.innerHeight,
      );

      if (isInitial.current) {
        setProperty('--header-position', 'sticky');
      }

      setProperty('--content-offset', `${downDelay}px`);

      if (isInitial.current || scrollY < downDelay) {
        setProperty('--header-height', `${downDelay + height}px`);
        setProperty('--header-mb', `${-downDelay}px`);
      } else if (top + height < -upDelay) {
        let offset = Math.max(height, scrollY - upDelay);
        setProperty('--header-height', `${offset}px`);
        setProperty('--header-mb', `${height - offset}px`);
      } else if (top === 0) {
        setProperty('--header-height', `${scrollY + height}px`);
        setProperty('--header-mb', `${-scrollY}px`);
      }

      if (top === 0 && scrollY > 0 && scrollY >= downDelay) {
        setProperty('--header-inner-position', 'fixed');
        removeProperty('--header-top');
        removeProperty('--logo-top');
      } else {
        removeProperty('--header-inner-position');
        setProperty('--header-top', '0px');
        setProperty('--logo-top', '0px');
      }
    }

    function updateLogoStyles() {
      if (!isHomePage) {
        return;
      }

      let fromScale = 1;
      let toScale = 36 / 64;
      let fromX = 0;
      let toX = 2 / 16;

      let scrollY = downDelay - window.scrollY;

      let scale = (scrollY * (fromScale - toScale)) / downDelay + toScale;
      scale = clamp(scale, fromScale, toScale);

      let x = (scrollY * (fromX - toX)) / downDelay + toX;
      x = clamp(x, fromX, toX);

      setProperty(
        '--logo-image-transform',
        `translate3d(${x}rem, 0, 0) scale(${scale})`,
      );

      let borderScale = 1 / (toScale / scale);
      let borderX = (-toX + x) * borderScale;
      let borderTransform = `translate3d(${borderX}rem, 0, 0) scale(${borderScale})`;

      setProperty('--logo-border-transform', borderTransform);
      setProperty('--logo-border-opacity', scale === toScale ? '1' : '0');
    }

    function updateStyles() {
      updateHeaderStyles();
      updateLogoStyles();
      isInitial.current = false;
    }

    updateStyles();
    window.addEventListener('scroll', updateStyles, { passive: true });
    window.addEventListener('resize', updateStyles);

    return () => {
      window.removeEventListener('scroll', updateStyles);
      window.removeEventListener('resize', updateStyles);
    };
  }, [isHomePage]);

  return (
    <>
      <header
        className="pointer-events-none relative z-50 flex flex-none flex-col"
        style={{
          height: 'var(--header-height)',
          marginBottom: 'var(--header-mb)',
        }}
      >
        {isHomePage && (
          <>
            <div
              ref={logoRef}
              className="order-last mt-[calc(theme(spacing.16)-theme(spacing.3))]"
            />
            <Container
              className="top-0 order-last -mb-3 pt-3"
              style={{
                position:
                  'var(--header-position)' as React.CSSProperties['position'],
              }}
            >
              <div
                className="top-[var(--logo-top,theme(spacing.3))] w-full"
                style={{
                  position:
                    'var(--header-inner-position)' as React.CSSProperties['position'],
                }}
              >
                <div className="relative">
                  <LogoContainer
                    className="absolute left-0 top-3 origin-left transition-opacity"
                    style={{
                      opacity: 'var(--logo-border-opacity, 0)',
                      transform: 'var(--logo-border-transform)',
                    }}
                  />
                  <Logo
                    to="/"
                    large
                    className="block h-16 w-28 origin-left"
                    src={logo_flat}
                    style={{ transform: 'var(--logo-image-transform)' }}
                  />
                </div>
              </div>
            </Container>
          </>
        )}
        <div
          ref={headerRef}
          className="top-0 z-10 h-16 pt-6"
          style={{
            position:
              'var(--header-position)' as React.CSSProperties['position'],
          }}
        >
          <Container
            className="top-[var(--header-top,theme(spacing.6))] w-full"
            style={{
              position:
                'var(--header-inner-position)' as React.CSSProperties['position'],
            }}
          >
            <div className="relative flex gap-4">
              <div className="flex flex-1">
                {!isHomePage && (
                  <LogoContainer>
                    <Logo to="/" src={logo_flat} />
                  </LogoContainer>
                )}
              </div>
              <div className="flex flex-1 justify-end md:justify-center">
                <Nav links={links} />
              </div>
              <div className="flex justify-end md:flex-1">
                <div className="pointer-events-auto">
                  {/* <ThemeToggle /> */}
                </div>
              </div>
            </div>
          </Container>
        </div>
      </header>
      {isHomePage && (
        <div
          className="flex-none"
          style={{ height: 'var(--content-offset)' }}
        />
      )}
    </>
  );
}
