export type LinkItem = {
  href: string;
  label: string;
};

export const links: LinkItem[] = [
  { href: '/', label: 'Home' },
  { href: '/articles', label: 'Articles' },
  { href: '/projects', label: 'Projects' },
  { href: '/about', label: 'About' },
];
